import isNewConsumerApp from './isNewConsumerApp';

// In new Consumer app, cache fetch requests by default
const options = isNewConsumerApp ? { cache: 'force-cache' } : {};

// This is a standard fetcher function that can be used with the SWR library.
const fetcher = async url => {
  const res = await fetch(url, options);

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error(`An error occurred while fetching "${url}"`);

    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;

    throw error;
  }

  return res.json();
};

export default fetcher;
