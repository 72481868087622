import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { logError } from 'commons/logger';
import isClient from 'commons/isClient';
import poll from 'commons/poll';
import { FB_LOGIN_PREV_PATH, FB_LOGIN_PII } from 'commons/constants';
import { setItemInStorage } from 'commons/localStorage';
import IconFacebook from 'components/icons/iconFacebook';

const FacebookLogin = ({ onFBCallback, staticContext, isFbLoading }) => {
  const [fbLoading, setFbLoading] = useState(isFbLoading || false);
  const [aToken, setAccessToken] = useState(null);

  const onFBResponse = response => {
    if (!response.status === 'connected') {
      return;
    }
    setAccessToken(response.authResponse?.accessToken);
  };

  useEffect(() => {
    try {
      if (window.FB) {
        window.FB.init({
          appId: isClient()
            ? process.env.NEXT_PUBLIC_FACEBOOK_APP_ID ??
              window._env_?.REACT_APP_FACEBOOK_APP_ID
            : staticContext
            ? process.env.NEXT_PUBLIC_FACEBOOK_APP_ID ??
              staticContext.envParams.REACT_APP_FACEBOOK_APP_ID
            : '',
          xfbml: true,
          version: 'v11.0',
        });
      }
      // TODO: Add handling if user hasn't consented to cookies necessary for this action. <script> element was moved from index.html to Google Tag Manager in CON-6866
    } catch (e) {
      logError(e);
    }
  }, [staticContext]);

  useEffect(() => {
    const fbLoggedInCb = response => {
      setFbLoading(false);
      onFBCallback({ ...response, accessToken: aToken });
    };

    const initializeFBwithPii = ({ name, email, id }) => {
      setItemInStorage(FB_LOGIN_PII, { name, email, id });
    };

    const onFBLoginStatusChange = () => {
      window.FB.api('/me?fields=email,name', response => {
        fbLoggedInCb(response);
        initializeFBwithPii(response);
      });
    };

    if (!aToken) {
      return;
    }
    window.FB.getLoginStatus(resp => {
      // See the onlogin handler
      onFBLoginStatusChange(resp);
    });
  }, [aToken, onFBCallback]);

  return (
    <div className="mt-[1.375rem] flex flex-col items-center">
      <button
        type="button"
        onClick={e => {
          e.preventDefault();
          setItemInStorage(FB_LOGIN_PREV_PATH, window.location.pathname);
          if (!fbLoading) {
            setFbLoading(true);

            poll({
              condition: () => !!window?.FB?.login,
              callback: () => {
                window.FB.login(onFBResponse, {
                  scope: 'public_profile,email',
                });
              },
            });
          }
        }}
        className="text-base w-full text-white font-bold cursor-pointer font-sans h-11 rounded bg-facebook border-0 relative flex justify-center items-center p-0 hover:[background:#0c64d7] [&_svg]:w-5 [&_svg]:h-5 [&_svg]:mr-2.5 [&_path]:fill-white"
      >
        <IconFacebook />
        {fbLoading ? `Signing you in...` : `Continue with Facebook`}
      </button>
    </div>
  );
};

FacebookLogin.propTypes = {
  onFBCallback: PropTypes.func,
  staticContext: PropTypes.string,
  isFbLoading: PropTypes.bool,
};

export default FacebookLogin;
