import { useCallback } from 'react';
import useProductCardContext from 'data-hooks/useProductCardContext';
import useUserCurrentPageType from 'hooks/useUserCurrentPageType';
import avo from 'analytics/avo';

const useProductWishlistEvent = () => {
  const product = useProductCardContext();
  const currentPage = useUserCurrentPageType();
  const productAddedToWishlistEvent = useCallback(() => {
    if (product?.id) {
      avo.productAddedToWishlist({
        productId: product.id,
        fabricId: 0,
        fabricName: '',
        price: product.price ? parseFloat(product.price) : 0,
        productFamily: product.productFamily || '',
        sku: product.sku,
        fabricColor: '',
        fullPrice: product.originalPrice
          ? parseFloat(product.originalPrice)
          : 0,
        isClearance: !!product.isClearance,
        quickship: !!product.quickship,
        location: currentPage,
        name: product.name,
        variant: product.label,
        category: product.productType,
        currency: 'USD',
      });
    }
  }, [product, currentPage]);

  const productRemovedFromWishlistEvent = useCallback(() => {
    if (product?.id) {
      avo.productRemovedFromWishlist({
        productId: product.id,
        category: product.productType,
        isClearance: !!product.isClearance,
        name: product.name,
        price: product.price ? parseFloat(product.price) : 0,
        quantity: 1,
        sku: product.sku,
        variant: product.label,
        fullPrice: product.originalPrice
          ? parseFloat(product.originalPrice)
          : 0,
        productFamily: product.productFamily || '',
        contentIds: [product.sku.split('-')[0]] || [],
        isFlashSale: product.variantsToShow[0]?.isFlashSale || false,
        currency: 'USD',
      });
    }
  }, [product]);

  const productWishlistEvent = useCallback(
    isFavorited => {
      if (!isFavorited) {
        productAddedToWishlistEvent();
      } else {
        productRemovedFromWishlistEvent();
      }
    },
    [productAddedToWishlistEvent, productRemovedFromWishlistEvent]
  );

  return {
    productWishlistEvent,
  };
};

export default useProductWishlistEvent;
