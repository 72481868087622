import React, { useMemo } from 'react';
import { cloneDeep } from 'lodash';

import usePostHogFeatureFlag from 'hooks/usePostHogFeatureFlag';
import useUserData from 'data-hooks/useUserData';
import useContentfulPromotionContent from 'hooks/useContentfulPromotionContent';
import SanitizedHtml from 'components/consumer/SanitizedHtml';
import mobileStaticNavItems from './mobileStaticNavItems';
import newMobileStaticNavItems from './newMobileStaticNavItems';

const useMobileNavItems = () => {
  const categoryNavTest = usePostHogFeatureFlag('navbar-categories-vs-rooms');
  const staticItems =
    categoryNavTest === 'test' ? newMobileStaticNavItems : mobileStaticNavItems;

  const { isLoggedIn } = useUserData();
  const { promotionData } = useContentfulPromotionContent();

  // const hideClearanceFlag = usePostHogFeatureFlag(
  //   'hide-clearance-and-flashSale-menu-item'
  // );

  // const showBlackFridaySalesLinks = !hideClearanceFlag;

  // TODO: Re-enable the clearance feature flag above
  const showBlackFridaySalesLinks = true;
  // const { value: hideSaleDropDown } = usePostHogFeatureFlag(
  //   'show-sale-dropdown-menu-item'
  // );
  const mobileNavItems = useMemo(() => {
    const updatedNavItems = cloneDeep(staticItems);

    const loginGroup = updatedNavItems.find(item => item.id === 'login');
    const loginItem = loginGroup.links.find(item => item.id === 'login');
    loginItem.label = isLoggedIn ? 'Dashboard' : 'Login';

    const designServicesGroup = updatedNavItems.find(
      item => item.id === 'design-services-group'
    );
    const designServicesItem = designServicesGroup.links.find(
      item => item.id === 'free-design-services'
    );
    designServicesItem.label = 'Free Design Services';

    const categoryGroup = updatedNavItems.find(
      item => item.id === 'categories'
    );
    const saleItem = categoryGroup.links.find(item => item.id === 'sale');

    const flashSaleItem = promotionData?.displayFlashSale
      ? {
          label: 'Flash Sale',
          id: 'flash-sale',
          to: '/flash-sale/',
        }
      : null;

    if (showBlackFridaySalesLinks) {
      saleItem.links = [
        {
          label: 'Clearance',
          id: 'clearance',
          to: '/clearance/',
        },
        flashSaleItem,
      ].filter(link => !!link);
    }

    if (
      promotionData?.saleDropdownAuxPromoText &&
      promotionData?.saleDropdownAuxPromoUrl
    ) {
      saleItem.links = [
        {
          label: (
            <SanitizedHtml
              as="span"
              html={promotionData?.saleDropdownAuxPromoText}
            />
          ),
          id: 'aux-promo-link',
          to: `${promotionData?.saleDropdownAuxPromoUrl}`,
        },
        ...saleItem.links,
      ];
    }

    return updatedNavItems;
  }, [
    staticItems,
    isLoggedIn,
    promotionData?.displayFlashSale,
    promotionData?.saleDropdownAuxPromoText,
    promotionData?.saleDropdownAuxPromoUrl,
    showBlackFridaySalesLinks,
  ]);

  // TODO: Add dynamic items to the mobile nav array
  return mobileNavItems;
};

export default useMobileNavItems;
